import React from "react"
import parse, { attributesToProps, domToReact } from "html-react-parser"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { tomorrow, prism } from "react-syntax-highlighter/dist/esm/styles/prism"
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import PostImage from "./post-image"

// https://dimitr.im/adding-syntax-highlighting-wordpress-gatsby#using-the-gutenberg-editor
const getCode = node => {
  if (node.children.length > 0 && node.children[0].name === 'code') {
    return node.children[0].children;
  } else {
    return node.children;
  }
}

const getLanguage = node => {
  if (node.attribs.class != null) {
    // return node.attribs.class;
    // Get the last element of class attribs
    return node.attribs.class.split(/\s+/).pop()
  }
  return null;
}

const getImage = node => {
  if (node.name === 'img') {
    return node;
  } else if (node.children != null) {
    for (let index = 0; index < node.children.length; index++) {
      let image = getImage(node.children[index]);
      if (image != null) return image;
    }
  }
}

const replaceMedia = node => {
  if (node.attribs && node.attribs.class === 'wp-block-image') {
    const image = getImage(node);
    // console.log(image)
    if (image != null) {
      return <PostImage src={image.attribs.src} alt={image.attribs.alt} width={image.attribs.width}/>;
    }
  }


  // if (node.name === 'div') {
  //   console.log(node)
  //   return <PostImage src={node.attribs.src} alt={node.attribs.alt} width={node.attribs.width}/>;
  // }
}

const replaceNodes = node => {
  // remove width & height from <img> tags
  // if (node.name === 'img') {
  //   const {width, height, alt, ...attribs} = node.attribs
  //   const props = attributesToProps(attribs)
  //   return <img {...props} alt={alt} />
  // }

  // if (node.attribs && node.attribs.class === 'wp-block-image') {
  //   const image = getImage(node);
  //   // console.log(image)
  //   if (image != null) {
  //     return <PostImage src={image.attribs.src} alt={image.attribs.alt} width={image.attribs.width}/>
  //   }
  // }

  if (node.name === 'pre') {
    // const codeStyle = localStorage.getItem('theme') === 'dark' ? prism : tomorrow
    return node.children.length > 0 &&
      <SyntaxHighlighter style={tomorrow} language={getLanguage(node)}>
        {domToReact(getCode(node))}
      </SyntaxHighlighter>
  }
}


const Content = ({ html }) => {
  const parsedHtml = parse(html, {replace: replaceNodes})
  // const parsedHtml = parse(html, {replace: replaceMedia})
  return (
    <section itemProp="articleBody">{parsedHtml}</section>
  )
}

export default Content
