import React from "react"
import { Link, graphql } from "gatsby"
import { Disqus, CommentCount } from "gatsby-plugin-disqus"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"

// import parse from "html-react-parser"

const BlogPost = ({ data, pageContext, location }) => {

  const post = data.allWpPost.nodes[0]
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = pageContext

  // https://github.com/tterb/gatsby-plugin-disqus
  let disqusConfig = {
    url: `${data.site.siteMetadata.siteUrl+location.pathname}`,
    identifier: post.slug,
    title: post.title,
  }

  // console.log(previous, next)

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.title}
        description={post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.title}</h1>
          <p>{post.date}{/*| <CommentCount config={disqusConfig} placeholder={'...'} />*/}</p>
        </header>
        <Content html={post.content} />
        <hr />
        <footer>
          <Bio />
          {/*<Disqus config={disqusConfig} />*/}
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`/posts/${previous.slug}`} rel="prev">
                ← {previous.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/posts/${next.slug}`} rel="next">
                {next.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allWpPost(filter: { slug: { eq: $slug } }) {
        nodes {
          id
          title
          content
          date(formatString: "MMMM DD, YYYY")
        }
    }
  }
`
